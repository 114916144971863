<template>
  <div class="room bold">
    <div class="room1" :class="{ active: info.roomNumber == layer + 1, disabled: item[layer + 1].leaseStatus != 0 }" @click="select(1)">
      {{ layer + 1 }}
    </div>
    <div class="room2" :class="{ active: info.roomNumber == layer + 2, disabled: item[layer + 2].leaseStatus != 0 }" @click="select(2)">
      {{ layer + 2 }}
    </div>
    <div class="room3" :class="{ active: info.roomNumber == layer + 3, disabled: item[layer + 3].leaseStatus != 0 }" @click="select(3)">
      {{ layer + 3 }}
    </div>
    <div class="room4" :class="{ active: info.roomNumber == layer + 4, disabled: item[layer + 4].leaseStatus != 0 }" @click="select(4)">
      {{ layer + 4 }}
    </div>
    <div class="room5" :class="{ active: info.roomNumber == layer + 5, disabled: item[layer + 5].leaseStatus != 0 }" @click="select(5)">
      {{ layer + 5 }}
    </div>
    <div class="room6" :class="{ active: info.roomNumber == layer + 6, disabled: item[layer + 6].leaseStatus != 0 }" @click="select(6)">
      {{ layer + 6 }}
    </div>
    <div class="room7" :class="{ active: info.roomNumber == layer + 7, disabled: item[layer + 7].leaseStatus != 0 }" @click="select(7)">
      {{ layer + 7 }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    item: {},
  },
  methods: {
    select(n) {
      this.info = this.item[this.layer + n]; //当前楼层 的 某个房间信息
      let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_3#B${n}${suffix}`); // 详情图
      this.$emit('select', this.info);
    },
  },
  computed: {
    layer() {
      return this.item.floor + '0'; // 当前楼层房间号前缀
    },
  },
};
</script>
<style lang="scss" scoped>
.room {
  background: url(../../../../assets/img/smartLocation/xf_img_compass.png) no-repeat;
  background-position: calc(100% - 10px) 30px;
  position: relative;
  margin: 0 auto;
  width: 668px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.room1 {
  cursor: pointer;
  position: absolute;
  width: 183px;
  height: 145px;
  top: 238px;
  left: 385px;
  line-height: 145px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B1_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B1_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B1_disabled.png) no-repeat;
  }
}
.room2 {
  cursor: pointer;
  position: absolute;
  width: 120px;
  height: 141px;
  top: 256px;
  left: 263px;
  line-height: 141px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B2_disabled.png) no-repeat;
  }
}
.room3 {
  cursor: pointer;
  position: absolute;
  width: 166px;
  height: 141px;
  top: 256px;
  left: 95px;
  line-height: 141px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B3_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B3_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B3_disabled.png) no-repeat;
  }
}
.room4 {
  cursor: pointer;
  position: absolute;
  width: 131px;
  height: 203px;
  top: 33px;
  left: 45px;
  line-height: 203px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B4_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B4_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B4_disabled.png) no-repeat;
  }
}
.room5 {
  cursor: pointer;
  position: absolute;
  width: 83px;
  height: 88px;
  top: 33px;
  left: 178px;
  line-height: 88px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B5_disabled.png) no-repeat;
  }
}
.room6 {
  cursor: pointer;
  position: absolute;
  width: 305px;
  height: 113px;
  top: 33px;
  left: 263px;
  line-height: 113px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B6_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B6_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B6_disabled.png) no-repeat;
  }
}
.room7 {
  cursor: pointer;
  position: absolute;
  width: 105px;
  height: 88px;
  top: 148px;
  left: 463px;
  line-height: 88px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B7_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B7_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3%23B7_disabled.png) no-repeat;
  }
}
</style>