<template>
  <div class="room bold">
    <div class="room1" :class="{ active: info.roomNumber == '501', disabled: item[501].leaseStatus != 0 }" @click="select(1)">501</div>
    <div class="room2" :class="{ active: info.roomNumber == '502', disabled: item[502].leaseStatus != 0 }" @click="select(2)">502</div>
    <div class="room3" :class="{ active: info.roomNumber == '503', disabled: item[503].leaseStatus != 0 }" @click="select(3)">503</div>
    <div class="room4" :class="{ active: info.roomNumber == '504', disabled: item[504].leaseStatus != 0 }" @click="select(4)">504</div>
    <div class="room5" :class="{ active: info.roomNumber == '505', disabled: item[505].leaseStatus != 0 }" @click="select(5)">505</div>
    <div class="room6" :class="{ active: info.roomNumber == '506', disabled: item[506].leaseStatus != 0 }" @click="select(6)">506</div>
    <div class="room7" :class="{ active: info.roomNumber == '507', disabled: item[507].leaseStatus != 0 }" @click="select(7)">507</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    item: {},
  },
  methods: {
    select(n) {
      this.info = this.item[500 + n];  //当前楼层 的 某个房间信息
      let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_3A${n}${suffix}`); // 详情图
      this.$emit('select', this.info);
    },
  },
};
</script>
<style lang="scss" scoped>
.room {
  background: url(../../../../assets/img/smartLocation/xf_img_compass.png) no-repeat;
  background-position: calc(100% - 10px) 30px;
  position: relative;
  margin: 0 auto;
  width: 668px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.room1 {
  cursor: pointer;
  position: absolute;
  width: 155px;
  height: 212px;
  top: 155px;
  left: 331px;
  line-height: 250px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3A1_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A1_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A1_disabled.png) no-repeat;
  }
}
.room2 {
  cursor: pointer;
  position: absolute;
  width: 109px;
  height: 128px;
  top: 252px;
  left: 220px;
  line-height: 128px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A2_disabled.png) no-repeat;
  }
}
.room3 {
  cursor: pointer;
  position: absolute;
  width: 150px;
  height: 128px;
  top: 252px;
  left: 68px;
  line-height: 128px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3A3_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A3_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A3_disabled.png) no-repeat;
  }
}
.room4 {
  cursor: pointer;
  position: absolute;
  width: 118px;
  height: 183px;
  top: 51px;
  left: 23px;
  line-height: 183px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3A4_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A4_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A4_disabled.png) no-repeat;
  }
}
.room5 {
  cursor: pointer;
  position: absolute;
  width: 76px;
  height: 80px;
  top: 51px;
  left: 143px;
  line-height: 80px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3A5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A5_disabled.png) no-repeat;
  }
}
.room6 {
  cursor: pointer;
  position: absolute;
  width: 276px;
  height: 80px;
  top: 51px;
  left: 220px;
  line-height: 80px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3A6_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A6_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A6_disabled.png) no-repeat;
  }
}
.room7 {
  cursor: pointer;
  position: absolute;
  width: 55px;
  height: 188px;
  top: 155px;
  left: 535px;
  line-height: 188px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_3A7_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A7_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_3A7_disabled.png) no-repeat;
  }
}
</style>