<template>
  <div class="room bold">
    <div class="room1" :class="{ active: info.roomNumber == '1101', disabled: item['1101'].leaseStatus != 0 }" @click="select(1)">
      {{ '1101' }}
    </div>
    <div class="room2" :class="{ active: info.roomNumber == '1102', disabled: item['1102'].leaseStatus != 0 }" @click="select(2)">
      {{ '1102' }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    item: {},
  },
  methods: {
    select(n) {
      this.info = this.item['110' + n]; //当前楼层 的 某个房间信息
      let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_4C${n}${suffix}`); // 详情图
      this.$emit('select', this.info);
    },
  },
};
</script>
<style lang="scss" scoped>
.room {
  background: url(../../../../assets/img/smartLocation/xf_img_compass.png) no-repeat;
  background-position: calc(100% - 10px) 30px;
  position: relative;
  margin: 0 auto;
  width: 668px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.room1 {
  cursor: pointer;
  position: absolute;
  width: 235px;
  height: 330px;
  top: 50px;
  left: 29px;
  line-height: 330px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4C1_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4C1_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4C1_disabled.png) no-repeat;
  }
}
.room2 {
  cursor: pointer;
  position: absolute;
  width: 318px;
  height: 330px;
  top: 50px;
  left: 266px;
  line-height: 280px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4C2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4C2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4C2_disabled.png) no-repeat;
  }
}
</style>