<template>
  <div class="room bold">
  
    <div
      :class="[{ active: info.roomNumber == getNo(v) , disabled: item[getNo(v)].leaseStatus != 0 }, 'room' + v]"
      @click="select(v)"
      v-for="(v, index) in 12"
      :key="index"
    >
      {{ getNo(v) }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    item: {},
  },
  methods: {
    select(n) {
      this.info = this.item[this.getNo(n)]; //当前楼层 的 某个房间信息
      let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_4#A${n}${suffix}`); // 详情图
      this.$emit('select', this.info);
    },
    getNo(n) {
      return n < 10 ? this.item.floor + '0' + n : this.item.floor + n;
    },
  },
};
</script>
<style lang="scss" scoped>
.room {
  background: url(../../../../assets/img/smartLocation/xf_img_compass.png) no-repeat;
  background-position: calc(100% - 10px) 30px;
  position: relative;
  margin: 0 auto;
  width: 668px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.room1 {
  cursor: pointer;
  position: absolute;
  width: 102px;
  height: 110px;
  top: 32px;
  left: 23px;
  line-height: 110px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A1_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A1_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A1_disabled.png) no-repeat;
  }
}
.room2 {
  cursor: pointer;
  position: absolute;
  top: 32px;
  left: 127px;
  width: 62px;
  height: 110px;
  line-height: 110px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_disabled.png) no-repeat;
  }
}
.room3 {
  cursor: pointer;
  position: absolute;
  top: 32px;
  left: 191px;
  width: 62px;
  height: 110px;
  line-height: 110px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_disabled.png) no-repeat;
  }
}
.room4 {
  cursor: pointer;
  position: absolute;
  top: 32px;
  left: 255px;
  width: 62px;
  height: 110px;
  line-height: 110px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_disabled.png) no-repeat;
  }
}
.room5 {
  cursor: pointer;
  position: absolute;
  top: 32px;
  left: 319px;
  width: 62px;
  height: 110px;
  line-height: 110px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A2_disabled.png) no-repeat;
  }
}
.room6 {
  cursor: pointer;
  position: absolute;
  width: 171px;
  height: 173px;
  top: 32px;
  left: 420px;
  line-height: 173px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A6_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A6_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A6_disabled.png) no-repeat;
  }
}
.room7 {
  cursor: pointer;
  position: absolute;
  top: 207px;
  left: 381px;
  width: 210px;
  height: 192px;
  line-height: 192px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A7_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A7_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A7_disabled.png) no-repeat;
  }
}
.room8 {
  cursor: pointer;
  position: absolute;
  top: 294px;
  left: 319px;
  width: 60px;
  height: 105px;
  line-height: 105px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_disabled.png) no-repeat;
  }
}
.room9 {
  cursor: pointer;
  position: absolute;
  top: 294px;
  left: 257px;
  width: 60px;
  height: 105px;
  line-height: 105px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_disabled.png) no-repeat;
  }
}
.room10 {
  cursor: pointer;
  position: absolute;
  top: 294px;
  left: 195px;
  width: 60px;
  height: 105px;
  line-height: 105px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_disabled.png) no-repeat;
  }
}
.room11 {
  cursor: pointer;
  position: absolute;
  top: 294px;
  left: 133px;
  width: 60px;
  height: 105px;
  line-height: 105px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_disabled.png) no-repeat;
  }
}
.room12 {
  cursor: pointer;
  position: absolute;
  top: 294px;
  left: 71px;
  width: 60px;
  height: 105px;
  line-height: 105px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23A8_disabled.png) no-repeat;
  }
}
</style>