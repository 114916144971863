<template>
  <div class="room bold">
    <div
      :class="[{ active: info.roomNumber == getNo(v) , disabled: item[getNo(v)].leaseStatus != 0 }, 'room' + v]"
      @click="select(v)"
      v-for="(v, index) in 10"
      :key="index"
    >
      {{ getNo(v) }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    item: {},
  },
  methods: {
    select(n) {
      this.info = this.item[this.getNo(n)]; //当前楼层 的 某个房间信息
      let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_4#B${n}${suffix}`); // 详情图
      this.$emit('select', this.info);
    },
    getNo(n) {
      return n < 10 ? this.item.floor + '0' + n : this.item.floor + n;
    },
  },
};
</script>
<style lang="scss" scoped>
.room {
  background: url(../../../../assets/img/smartLocation/xf_img_compass.png) no-repeat;
  background-position: calc(100% - 10px) 30px;
  position: relative;
  margin: 0 auto;
  width: 668px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.room1 {
  cursor: pointer;
  position: absolute;
  width: 228px;
  height: 103px;
  top: 79px;
  left: 41px;
  line-height: 103px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B1_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B1_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B1_disabled.png) no-repeat;
  }
}
.room2 {
  cursor: pointer;
  position: absolute;
  top: 79px;
  left: 271px;
  width: 165px;
  height: 88px;
  line-height: 88px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B2_disabled.png) no-repeat;
  }
}
.room3 {
  cursor: pointer;
  position: absolute;
  top: 79px;
  left: 474px;
  width: 128px;
  height: 129px;
  line-height: 129px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B3_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B3_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B3_disabled.png) no-repeat;
  }
}
.room4 {
  cursor: pointer;
  position: absolute;
  top: 209px;
  left: 445px;
  width: 157px;
  height: 144px;
  line-height: 144px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B4_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B4_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B4_disabled.png) no-repeat;
  }
}
.room5 {
  cursor: pointer;
  position: absolute;
  top: 286px;
  left: 360px;
  width: 83px;
  height: 66px;
  line-height: 66px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_disabled.png) no-repeat;
  }
}
.room6 {
 cursor: pointer;
  position: absolute;
  top: 286px;
  left: 275px;
  width: 83px;
  height: 66px;
  line-height: 66px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_disabled.png) no-repeat;
  }
}
.room7 {
  cursor: pointer;
  position: absolute;
  top: 286px;
  left: 190px;
  width: 83px;
  height: 66px;
  line-height: 66px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_disabled.png) no-repeat;
  }
}
.room8 {
  cursor: pointer;
  position: absolute;
  top: 286px;
  left: 105px;
  width: 83px;
  height: 66px;
  line-height: 66px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_disabled.png) no-repeat;
  }
}
.room9 {
  cursor: pointer;
  position: absolute;
  top: 286px;
  left: 20px;
  width: 83px;
  height: 66px;
  line-height: 66px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B5_disabled.png) no-repeat;
  }
}
.room10 {
  cursor: pointer;
  position: absolute;
  top: 183px;
  left: 20px;
  width: 83px;
  height: 82px;
  line-height: 82px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B10_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B10_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_4%23B10_disabled.png) no-repeat;
  }
}
</style>