<template>
  <div class="room bold">
    <div class="room1" :class="{ active: info.roomNumber == layer + 1, disabled: item[layer + 1].leaseStatus != 0 }" @click="select(1)">
      {{ layer + 1 }}
    </div>
    <div class="room2" :class="{ active: info.roomNumber == layer + 2, disabled: item[layer + 2].leaseStatus != 0 }" @click="select(2)">
      {{ layer + 2 }}
    </div>
    <div class="room3" :class="{ active: info.roomNumber == layer + 3, disabled: item[layer + 3].leaseStatus != 0 }" @click="select(3)">
      {{ layer + 3 }}
    </div>
    <div class="room4" :class="{ active: info.roomNumber == layer + 4, disabled: item[layer + 4].leaseStatus != 0 }" @click="select(4)">
      {{ layer + 4 }}
    </div>
    <div class="room5" :class="{ active: info.roomNumber == layer + 5, disabled: item[layer + 5].leaseStatus != 0 }" @click="select(5)">
      {{ layer + 5 }}
    </div>
    <div class="room6" :class="{ active: info.roomNumber == layer + 6, disabled: item[layer + 6].leaseStatus != 0 }" @click="select(6)">
      {{ layer + 6 }}
    </div>
    <div class="room7" :class="{ active: info.roomNumber == layer + 7, disabled: item[layer + 7].leaseStatus != 0 }" @click="select(7)">
      {{ layer + 7 }}
    </div>
    <div class="room8" :class="{ active: info.roomNumber == layer + 8, disabled: item[layer + 8].leaseStatus != 0 }" @click="select(8)">
      {{ layer + 8 }}
    </div>
    <div class="room9" :class="{ active: info.roomNumber == layer + 9, disabled: item[layer + 9].leaseStatus != 0 }" @click="select(9)">
      {{ layer + 9 }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    item: {},
  },
  methods: {
    select(n) {
      this.info = this.item[this.layer + n]; //当前楼层 的 某个房间信息
      let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_2#A${n}${suffix}`); // 详情图
      this.$emit('select', this.info);
    },
  },
  computed: {
    layer() {
      return this.item.floor + '0'; // 当前楼层房间号前缀
    },
  },
};
</script>
<style lang="scss" scoped>
.room {
  background: url(../../../../assets/img/smartLocation/xf_img_compass.png) no-repeat;
  background-position: calc(100% - 10px) 30px;
  position: relative;
  margin: 0 auto;
  width: 668px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.room1 {
  cursor: pointer;
  position: absolute;
  width: 165px;
  height: 122px;
  top: 278px;
  left: 410px;
  line-height: 122px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A1_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A1_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A1_disabled.png) no-repeat;
  }
}
.room2 {
  cursor: pointer;
  position: absolute;
  top: 297px;
  left: 303px;
  width: 105px;
  height: 103px;
  line-height: 103px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_disabled.png) no-repeat;
  }
}
.room3 {
  cursor: pointer;
  position: absolute;
  top: 297px;
  left: 196px;
  width: 105px;
  height: 103px;
  line-height: 103px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_disabled.png) no-repeat;
  }
}
.room4 {
  cursor: pointer;
  position: absolute;
  width: 157px;
  height: 122px;
  top: 278px;
  left: 37px;
  line-height: 122px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A4_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A4_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A4_disabled.png) no-repeat;
  }
}
.room5 {
  cursor: pointer;
  position: absolute;
  width: 135px;
  height: 121px;
  top: 155px;
  left: 37px;
  line-height: 121px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A5_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A5_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A5_disabled.png) no-repeat;
  }
}
.room6 {
  cursor: pointer;
  position: absolute;
  width: 157px;
  height: 122px;
  top: 31px;
  left: 37px;
  line-height: 122px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A6_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A6_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A6_disabled.png) no-repeat;
  }
}
.room7 {
  cursor: pointer;
  position: absolute;
  top: 31px;
  left: 196px;
  width: 105px;
  height: 103px;
  line-height: 103px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_disabled.png) no-repeat;
  }
}
.room8 {
  cursor: pointer;
  position: absolute;
  top: 31px;
  left: 303px;
  width: 105px;
  height: 103px;
  line-height: 103px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A2_disabled.png) no-repeat;
  }
}
.room9 {
  cursor: pointer;
  position: absolute;
  width: 165px;
  height: 123px;
  top: 31px;
  left: 410px;
  line-height: 123px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A9_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A9_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23A9_disabled.png) no-repeat;
  }
}
</style>