<template>
  <div class="room bold">
    <div class="room1" :class="{ active: info.roomNumber == layer + 1, disabled: item[layer + 1].leaseStatus != 0 }" @click="select(1)">
      {{ layer + 1 }}
    </div>
    <div class="room2" :class="{ active: info.roomNumber == layer + 2, disabled: item[layer + 2].leaseStatus != 0 }" @click="select(2)">
      {{ layer + 2 }}
    </div>
    <div class="room3" :class="{ active: info.roomNumber == layer + 3, disabled: item[layer + 3].leaseStatus != 0 }" @click="select(3)">
      {{ layer + 3 }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    item: {},
  },
  methods: {
    select(n) {
      this.info = this.item[this.layer + n];  //当前楼层 的 某个房间信息
      let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_2#C${n}${suffix}`); // 详情图
      this.$emit('select', this.info);
    },
  },
  computed: {
    layer() {
      return this.item.floor + '0'; // 当前楼层房间号前缀  
    },
  },
};
</script>
<style lang="scss" scoped>
.room {
  background: url(../../../../assets/img/smartLocation/xf_img_compass.png) no-repeat;
  background-position: calc(100% - 10px) 30px;
  position: relative;
  margin: 0 auto;
  width: 668px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.room1 {
  cursor: pointer;
  position: absolute;
  width: 259px;
  height: 369px;
  top: 31px;
  left: 308px;
  line-height: 369px;
  text-indent: 100px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C1_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C1_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C1_disabled.png) no-repeat;
  }
}
.room2 {
  cursor: pointer;
  position: absolute;
  width: 259px;
  height: 218px;
  top: 182px;
  left: 47px;
  line-height: 280px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C2_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C2_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C2_disabled.png) no-repeat;
  }
}
.room3 {
  cursor: pointer;
  position: absolute;
  width: 376px;
  height: 122px;
  top: 31px;
  left: 47px;
  line-height: 120px;
  background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C3_normal.png) no-repeat;
  &.active {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C3_selected.png) no-repeat !important;
  }
  &.disabled {
    background: url(../../../../assets/img/smartLocation/lc/lc_img_2%23C3_disabled.png) no-repeat;
  }
}

</style>