<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">企业办事</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">智能选址</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">选地块</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">选楼栋</el-breadcrumb-item>
      <el-breadcrumb-item>选房</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="session">
      <div class="title fl-c">
        <img src="../../../assets/img/smartLocation/back_loudong.png" alt="" class="title-img back" @click="back()" />
        <span class="back" @click="back()">返回楼栋选择</span>
      </div>
      <div class="header-box fl-c-b">
        <div>
          <span class="number bold">{{ build }}号楼</span>余{{ notLeasedNum }}套
        </div>
        <a class="link" @click="go()">入驻企业概况</a>
      </div>
      <div class="layer" v-for="(item, index) in list" :key="index">
        <div class="fl-c-b">
          <div class="layer-number">第{{ getlayer(item.floor) }}层</div>
          <div class="sq-zk zk" v-if="item.show == false" @click="item.show = true">
            展开
            <img src="../../../assets/img/smartLocation/arrow_zk.png" alt="" class="show-img" />
          </div>
          <div class="sq-zk sq" v-else @click="item.show = false">
            收起
            <img src="../../../assets/img/smartLocation/arrow_sq.png" alt="" class="show-img" />
          </div>
        </div>
        <div class="floor-plan" v-if="item.show == true">
          <div class="item-title">楼层平面图</div>
          <div class="fl-box">
            <div class="room-box">
              <template v-if="build == 2">
                <HouseType3A :item="item" @select="select($event, item)" v-if="item.floor == 1 || item.floor == 2 || item.floor == 3"></HouseType3A>
                <HouseType3B :item="item" @select="select($event, item)" v-else-if="item.floor == 4"></HouseType3B>
                <HouseType3C :item="item" @select="select($event, item)" v-else></HouseType3C>
              </template>
              <template v-if="build == 3">
                <HouseType2A :item="item" @select="select($event, item)" v-if="item.floor == 5"></HouseType2A>
                <HouseType2B :item="item" @select="select($event, item)" v-else></HouseType2B>
              </template>
              <template v-if="build == 4">
                <HouseType4A :item="item" @select="select($event, item)" v-if="item.floor < 7"></HouseType4A>
                <HouseType4B :item="item" @select="select($event, item)" v-else-if="item.floor < 11"></HouseType4B>
                <HouseType4C :item="item" @select="select($event, item)" v-else></HouseType4C>
              </template>
            </div>
            <div class="room-card">
              <template v-if="item.info">
                <div class="room-name">
                  {{ item.info.roomNumber }}房间
                  <span class="room-status status1" v-if="item.info.leaseStatus == 0">待租赁</span>
                  <span class="room-status status2" v-else>已租赁</span>
                </div>
                <div class="fl-c mb16 enterprise-name" v-if="item.info.leaseStatus != 0">
                  <img src="../../../assets/img/smartLocation/rzqy_icon.png" alt="" />{{ item.info.leaseReserveBusiness }}
                </div>
                <div class="fl-box">
                  <div class="room-card-box bold"><img :src="item.info.url" alt="" />{{ item.info.roomNumber }}</div>
                  <div>
                    <div class="room-info">建筑面积：{{ item.info.totalArea }}平方米</div>
                    <div class="room-info">使用面积：{{ item.info.userArea }}平方米</div>
                    <div class="room-info">公摊：{{ item.info.sharedArea }}平方米</div>
                    <!-- <div class="room-info">朝向：{{ item.info.orientation }}</div> -->
                  </div>
                </div>
                <div class="clearfix">
                  <el-button type="primary" class="fr btn" @click="apply(item.info.id)" v-if="item.info.leaseStatus == 0">提交申请</el-button>
                  <el-button class="fr btn disabled" disabled v-else>提交申请</el-button>
                </div>
              </template>
              <template v-else>
                <div class="no-select">请选择房间</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="申请信息" :visible.sync="centerDialogVisible" width="50vw" center @close="closeDialog">
      <el-form ref="from" :model="formData" :rules="rules" v-if="centerDialogVisible" label-width="150px" label-position="left">
        <el-form-item prop="contactPerson" label="联系人">
          <el-input placeholder="请输入联系人" v-model.trim="formData.contactPerson" :maxlength="20"> </el-input>
        </el-form-item>
        <el-form-item prop="phone" label="联系电话">
          <el-input placeholder="请输入联系电话" v-model.trim="formData.phone" :maxlength="11"> </el-input>
        </el-form-item>
        <el-form-item prop="businessLicense" label="营业执照复印件">
           <uploader v-model="formData.businessLicense"></uploader>
        </el-form-item>
         <el-form-item prop="legalPersonIdCard" label="法人身份证复印件">
           <uploader v-model="formData.legalPersonIdCard"></uploader>
        </el-form-item>
         <el-form-item prop="powerAttorneyOperatorIdCard" label="供经办人身份证复印件及委托书">
           <uploader v-model="formData.powerAttorneyOperatorIdCard"></uploader>
        </el-form-item>
         <el-form-item prop="certificatesEnterprise" label="企业相关资质证明复印件">
           <uploader v-model="formData.certificatesEnterprise"></uploader>
        </el-form-item>
         <el-form-item prop="investmentAgreement" label="招商引资企业另需再提供投资协议书">
           <uploader v-model="formData.investmentAgreement"></uploader>
        </el-form-item>
         <el-form-item prop="applicationFormLeaseAccess" label="温州瓯江口产业集聚区发展大厦租赁准入申请表">
           <uploader v-model="formData.applicationFormLeaseAccess"></uploader>
        </el-form-item>
         <el-form-item prop="relocatedEnterprises" label="温州瓯江口产业集聚区迁入企业申请表">
           <uploader v-model="formData.relocatedEnterprises"></uploader>
        </el-form-item>
         <el-form-item prop="writtenReport" label="入驻企业提供一份书面报告">
           <uploader v-model="formData.writtenReport"></uploader>
        </el-form-item>



        <div class="fl-c-c">
          <el-button type="primary" class="btn" @click="submit">提 交</el-button>
          <el-button type="default" class="btn" @click="centerDialogVisible = false">关 闭</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Uploader from './components/uploader.vue';
import HouseType2A from './HouseType/HouseType2A'; // 2号楼 5层
import HouseType2B from './HouseType/HouseType2B'; // 2号楼 6~14层
import HouseType3A from './HouseType/HouseType3A'; // 3号楼 2 3层
import HouseType3B from './HouseType/HouseType3B'; // 3号楼 4层
import HouseType3C from './HouseType/HouseType3C'; // 3号楼 5 ~ 14层
import HouseType4A from './HouseType/HouseType4A'; // 4号楼 4~6层
import HouseType4B from './HouseType/HouseType4B'; // 4号楼 7~10层
import HouseType4C from './HouseType/HouseType4C'; // 4号楼 11层
export default {
  data() {
    var validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入联系电话'));
      } else if (!this.isValidPhone(value)) {
        callback(new Error('请输入正确的联系电话'));
      } else {
        callback();
      }
    };
    return {
      build: 2,
      land: 1,
      list: [],
      centerDialogVisible: false,
      /**
       * @type import("@/common/api/modules/smartLocation").SelectHouseApplyForm 
       */
      formData: {
        contactPerson: '',
        phone: '',
        businessLicense:[],
        legalPersonIdCard:[],
        powerAttorneyOperatorIdCard:[],
        certificatesEnterprise:[],
        investmentAgreement:[],
        applicationFormLeaseAccess:[],
        relocatedEnterprises:[],
        writtenReport:[],
      },
      rules: {
        contactPerson: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true,message: '请输入联系人', trigger: 'blur' }],
         businessLicense:[{ required: true,message: '请上传营业执照复印件', trigger: 'blur' }],
        legalPersonIdCard:[{ required: true,message: '请上传法人身份证复印件', trigger: 'blur' }],
        powerAttorneyOperatorIdCard:[{ required: true,message: '请上传供经办人身份证复印件及委托书', trigger: 'blur' }],
        certificatesEnterprise:[{ required: true,message: '请上传企业相关资质证明复印件', trigger: 'blur' }],
        investmentAgreement:[{ required: true,message: '请上传招商引资企业另需再提供投资协议书', trigger: 'blur' }],
        applicationFormLeaseAccess:[{ required: true,message: '请上传温州瓯江口产业集聚区发展大厦租赁准入申请表', trigger: 'blur' }],
        relocatedEnterprises:[{ required: true, message: '请上传温州瓯江口产业集聚区迁入企业申请表',trigger: 'blur' }],
        writtenReport:[{ required: true,message: '请上传入驻企业提供一份书面报告', trigger: 'blur' }],

      },
    };
  },
  created() {
    this.build = this.$route.params.build;
    this.land = this.$route.params.land;
    this.getData();
  },
  components: {
    HouseType2A,
    HouseType2B,
    HouseType3A,
    HouseType3B,
    HouseType3C,
    HouseType4A,
    HouseType4B,
    HouseType4C,
    Uploader,
  },
  methods: {
    isValidPhone(str) {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      return reg.test(str);
    },
    apply(id) {
      this.formData.roomId = id;
      this.centerDialogVisible = true;
    },
    closeDialog() {
      // 点击dialog右上角关闭按钮事件
      this.$refs.from.resetFields();
      this.formData = {
        contactPerson: '',
        phone: '',
      };
    },
    submit() {
      this.$refs['from'].validate((valid) => {
        if (valid) {
          this.$api.buildingLeaseApply({...this.formData,land:this.land}).then((res) => {
            if (res.success) {
              this.$message.success({ message: '申请成功', offset: 80 });
              this.centerDialogVisible = false;
              this.getData();
            }
          });
        } else {
          return false;
        }
      });
    },
    getData() {
      this.$api.getBuildingleaseList({ land: this.land, build: this.build }).then((res) => {
        if (res.success) {
          this.list = res.result.map((v) => {
            v.show = false;
            v.info = null;
            return v;
          });
          let floorList = res.result.map((v) => {
            return v.floor;
          });
          this.$store.commit('updateValue', { floorList: floorList });
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    go() {
      this.$router.push({ name: 'smartLocationOverview', params: { land: this.land, build: this.build } });
    },
    select(v, item) {
      item.info = v;
    },
    getlayer(num) {
      num = Number(num);
      var upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
      var length = String(num).length;
      if (length == 1) {
        return upperCaseNumber[num];
      } else if (length == 2) {
        if (num == 10) {
          return upperCaseNumber[num];
        } else if (num > 10 && num < 20) {
          return '十' + upperCaseNumber[String(num).charAt(1)];
        } else {
          return upperCaseNumber[String(num).charAt(0)] + '十' + upperCaseNumber[String(num).charAt(1)].replace('零', '');
        }
      }
    },
  },
  computed: {
    ...mapState({
      notLeasedNum: (state) => state.notLeasedNum,
    }),
  },
  mounted() {},
};
</script>


<style lang='scss' scoped>
.session {
  padding: 0 20px 22px;
  background-color: #fff;
}

.title {
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #000000;
  line-height: 51px;
  margin-bottom: 16px;
}

.title-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.back {
  cursor: pointer;
}

.item-title {
  font-size: 14px;
  color: #000;
  padding-left: 16px;
  position: relative;
  margin-bottom: 16px;
}

.item-title::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #0e459c;
}

.header-box {
  background: #eff7ff;
  font-size: 16px;
  color: #000000;
  line-height: 95px;
  padding: 0 20px;
  margin-bottom: 16px;
}

.number {
  font-size: 21px;
  color: #000000;
  margin-right: 20px;
}

.link {
  color: #0e459c;
  text-decoration: none;
  cursor: pointer;
}

.layer {
  background: #fafafa;
  border: 1px solid #eeeeee;
  margin-bottom: 16px;
  padding: 20px;
}

.layer-number {
  font-size: 18px;
  color: #333333;
  font-weight: 600;
}

.sq-zk {
  background: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 96px;
  height: 40px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.show-img {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  vertical-align: middle;
}

.sq {
  margin-top: 14px;
}

.floor-plan {
  margin-top: -10px;
}

.room-card {
  background: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(97, 143, 221, 0.26);
  border-radius: 4px;
  width: 364px;
  height: max-content;
  padding: 20px;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}

.room-box {
  margin-right: 20px;
  width: calc(100% - 384px);
  height: 450px;
  border: 10px solid #0e459c;
  background: #fff;
  font-size: 14px;
  color: #333333;
  text-align: center;
}

.no-select {
  line-height: 100px;
  text-align: center;
  font-size: 16px;
  color: #999999;
}

.room-status {
  display: inline-block;
  font-size: 10px;
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
  margin-left: 6px;
}

.status1 {
  color: #0e459c;
  background: rgba(74, 126, 255, 0.15);
}

.status2 {
  color: #d18308;
  background: rgba(228, 157, 46, 0.15);
}

.room-name {
  margin-bottom: 20px;
}

.room-card-box {
  width: 140px;
  height: 140px;
  margin-right: 16px;
  background: #fafafa;
  margin-bottom: 16px;
  color: #333333;
  position: relative;
  text-align: center;
  line-height: 150px;
  z-index: 1;
  img {
    display: block;
    max-width: 95%;
    max-height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
.enterprise-name {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}

.room-info {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-bottom: 10px;
}
.room-card .btn {
  height: 40px;
  line-height: 20px;
  padding: 10px 25px;
}
.room-card .disabled.btn {
  background: #cccccc;
  color: #fff;
}
</style>